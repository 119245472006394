import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';
import Helmet from "react-helmet"
import { AwesomeButton } from "react-awesome-button";
import { navigate } from 'gatsby'

const NotFoundPage = ({ data }) => (
  <>
    <Helmet
  title={data.wordpressPage.acf.error_title_seo} 
  titleTemplate={data.wordpressPage.acf.error_title_seo}
  meta={[
    {
      name: `description`,
      content: data.wordpressPage.acf.error_description_seo, 
    },
    {
      property: `og:title`,
      content: data.wordpressPage.acf.error_title_seo,
    },
    {
      name: `thumbnail`,
      content: data.wordpressPage.acf.error_img_seo.localFile.url,
    },
    {
      property: `og:image`,
      content: data.wordpressPage.acf.error_img_seo.localFile.url, 
    },
    {
      property: `og:description`,
      content: data.wordpressPage.acf.error_description_seo,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]}
/>
  <div className="small-banner-content">
  <div style={{backgroundImage: `url('` + data.wordpressPage.acf.error_banner_img.localFile.url + `')`, backgroundPosition: `center`}} className="background-small"></div>
        <div className="text-on-banner">  
        <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
          <h2 className='smallbanner-title'>{data.wordpressPage.acf.error_title}</h2>
          </ScrollAnimation>
      </div>
      <div className='right-bottom-curve-cont'>
        <div className="right-bottom-curve"></div>
      </div>
      </div>
  <Layout lang="pl" pageInfo={{ pageName: "404" }}>
    <Container className='p404'>
    <h1 className='headertext'>{data.wordpressPage.acf.error_head_text}</h1>
    <p className='maintext'>{data.wordpressPage.acf.error_main_text}</p>
    <div className='centeredbutton p404-button'>
    <AwesomeButton
          type="yellow"
          onPress={() => {
            navigate('/')
          }}
        >
        Strona główna
        </AwesomeButton>
        </div>
    </Container>
  </Layout>
  </>
)

export default NotFoundPage
export const query = graphql`
query atatataua {
 wordpressPage(title: {eq: "404"}) {
   acf {
     error_title_seo
     error_description_seo
     error_img_seo {
       localFile {
         url
       }
     }
     error_banner_img {
       localFile {
         url
       }
     }
     error_title
     error_head_text
     error_main_text
   }
 }
}

`